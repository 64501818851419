/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://z3rxuc2aqbf7nkqhgkqnedlmmq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ut2zs55terewxi2fxlol45msw4",
    "aws_cognito_identity_pool_id": "us-east-1:fab4eea0-db7f-4413-a527-0e63932e418e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4jexv6wdn",
    "aws_user_pools_web_client_id": "3qu68fsupfvkve4cf3lu8takri",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "platformde0a42c18c744a9cae17f9d001d263e91c7b0-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
